import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import * as echarts from "echarts";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import axios from "axios";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
